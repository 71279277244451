.pdfContainer {
  margin:auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfDocument {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfPage {
  box-shadow: 0 0 2px rgba(0, 0, 0, .5);
}

.pdfPage > canvas {
  max-width: 100%;
  height: auto !important;
}