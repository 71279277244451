.nav {
  background: #E9724C;
  min-height: 58px;
}

.navImage {
  height: 30px;
  width: 45px;
}

.userName {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.menuButton {
  &:after {
    content: none
  }
}
