.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../../images/bg-image.jpeg);
  background-position: center;
  background-size: cover;
  z-index: -100;
}

.background-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(black, 0.25);
  z-index: -99;
}