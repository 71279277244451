// Bootstrap default overrides

$theme-colors: (
  "primary": #40bf8a,
  "callout": #3FA7D6,
  "light": #f8f9fa,
  "light2": #e9ecef,
  "light3": #dee2e6,
  "dark": #163969
);

$border-radius: .15rem;
$border-radius-lg: .25rem;
$border-radius-sm: .15rem;

$input-btn-focus-width: 0;
$input-btn-focus-color: theme-color("primary");
$badge-font-weight: 400;

$body-color: #343a40;
$font-family-sans-serif: "Open Sans", Arial, sans-serif;
$font-family-monospace: "Inconsolata", "Courier New", monospace;
$headings-font-family: "Open Sans", Arial, sans-serif;
$headings-font-weight: 300;

$dropdown-font-size: 0.875rem;
$dropdown-padding-y: 0;
$dropdown-spacer: 0;
$dropdown-divider-margin-y: 0;

$modal-xl: 1300px;
$modal-lg: 1000px;

$spacers: (
  "half": 0.125rem
);

$tooltip-bg: #3FA7D6;
$tooltip-opacity: 1.0;

// Bootstrap and its default variables
@import "../node_modules/bootstrap/scss/bootstrap";

// Bootstrap overrides

.alert {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.modal-title {
  font-size: $h5-font-size;
}

.dropdown-toggle::after {
  margin-left: 0.9rem;
}

.dropdown-item {
  font-size: 0.875rem;
}

.row {
  overflow-wrap: break-word;
}

// React-Table

@import "../node_modules/react-table/react-table.css";

// React-Table overrides

// animation to let the user know an item update was successful
@-webkit-keyframes successAnimation {
  0% {
    background: rgba(0,255,0,0.25);
  }
}
@keyframes successAnimation {
  0% {
    background: rgba(0,255,0,0.25);
  }
}

.ReactTable {
  background: theme-color("light");
  border: 1px solid $border-color;
  border-radius: .25rem;
  font-size: 0.8rem;
  min-height: 100px;
}

.ReactTable .rt-htead .rt-th {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReactTable .rt-thead .rt-th.rt-resizable-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: visible !important;
  white-space: normal !important;
  font-weight: $font-weight-bold;
  flex-grow: 1;
}

.ReactTable .rt-th.centered, .ReactTable .rt-td.centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactTable .rt-th.left {
  .rt-resizable-header-content {
    text-align: left;
  }
}

.ReactTable .rt-tbody {
  overflow-x: hidden;
}

.ReactTable .rt-td.actions {
  .action-button {
    padding: 0.05rem 0.2rem;
    font-size: 0.75rem;
  }
}

.ReactTable .rt-td.no-padding {
  padding: 0;
}

.ReactTable .rt-td.allow-overflow {
  overflow: visible;
}

.ReactTable .rt-tr.recently-updated {
  -webkit-animation: successAnimation 2s;
  animation: successAnimation 2s;
}

.ReactTable {
  .-sort-desc {
    box-shadow: none !important;
    &:after {
      content: "▼";
      color: theme-color("primary");
    }
  }

  .-sort-asc {
    box-shadow: none !important;
    &:after {
      content: "▲";
      color: theme-color("primary");
    }
  }
}

// Dropzone overrides

.dropzone {
  min-height: 100px;
  background: theme-color("light");
  border-width: 2px;
  border-radius: 0.25rem;
  border-color: theme-color("primary");
  border-style: dashed;
  outline: none;
  transition: 'background 300 ease-in-out';
  &.active {
    background: theme-color("light3");
  }
}

// Stripe overrides

.StripeElement--focus.StripeElement--invalid {
  border-color: theme-color("danger");
}
.StripeElement--focus {
  border-color: #a0dfc5;
}

// React datepicker

@import "../node_modules/react-datepicker/dist/react-datepicker.css";

// React datepicker overrides

.react-datepicker {
  border-radius: 0.25rem;
  font-family: $font-family-sans-serif;
}
.react-datepicker-wrapper {
  border: none;
  width: 100%;
}
.react-datepicker__input-container {
  border: none;
  width: 100%;
}
.react-datepicker, .react-datepicker-wrapper {
  input {
    width: 100% !important;
    border-width: 1px;
    border-radius: .15rem;
    border-color: $gray-400;
    border-style: solid;
    font-size: $font-size-base;
    padding-top: $input-padding-y;
    padding-bottom: $input-padding-y;
    padding-left: $input-padding-x;
    padding-right: $input-padding-x;
    &:focus {
      border-color: #a0dfc5;
    }
    box-shadow: none;
    outline: none;
  }
}
.react-datepicker__time-list-item, .react-datepicker__day {
  &--selected, &--keyboard-selected {
    background-color: theme-color("primary") !important;
  }
}

// Global styles

body, #root, .App {
  position: absolute;
  height: 100%;
  width: 100%;
}

.btn-wide {
  min-width: 140px;
}

.btn-semi-wide {
  min-width: 100px;
}

.hide-after-content {
  &:after {
    content: none
  }
}

.no-decoration {
  text-decoration: none !important;
}

.subheading {
  font-family: Montserrat, Arial, sans-serif;
  font-weight: 500;
}

.dropzone--isActive {
  border-style: solid;
  border-width: 4px;
  border-color: theme-color("primary");
  background-color: theme-color("light3");
}

.info_level_1 {
  margin-top: 15.0pt;
  margin-right: 0in;
  margin-bottom: .0001pt;
  margin-left: 0in;
  line-height: 30.0pt;
  font-size: 15px;
  font-family: "Arial",sans-serif;
  background: white;
  vertical-align: top;
  color: #444444;
  > p {
    line-height: normal;
  }
  > ul {
    margin-top: -10px;
    > li {
      list-style-type: disc;
    line-height: 20px;
    margin-left: -20px;
    }
  }
}

.info_list {
  list-style-type: upper-roman;
  font-size: 20px;
  font-family: "Arial",sans-serif;
  color: #444444;
  font-weight: bold;
  margin-left: -40px;
  > li span{
    font-weight: normal;
  }
  > li ol {
    list-style-type: upper-alpha;
    font-size: 15px;
    font-family: "Arial",sans-serif;
    color: #444444;
    margin-left: -20px;
    > li p {
      font-weight: normal;
      line-height: normal;
    }
    > li ol {
      list-style-type: decimal;
      font-weight: bold;
      > li a {
        color: #1C75BC;
      }
    }
    > li ul {
      list-style-type: disc;
      font-weight: normal;
      line-height: 20px;
      font-size: 15px;
      font-family: "Arial",sans-serif;
      color: #444444;
      vertical-align: top;
      margin-left: -20px;
      margin-top: -15px;
      margin-bottom: 1rem;
      > li a {
        color: #1C75BC;
      }
    }
  }
  > li ul {
    list-style-type: disc;
    font-weight: normal;
    line-height: 25px;
    font-size: 15px;
    font-family: "Arial",sans-serif;
    color: #444444;
    vertical-align: top;
  }
  > li p {
    font-size: 15px;
    font-family: "Arial",sans-serif;
    color: #444444;
    font-weight: normal;
    line-height: normal;
    > a {
      color: #1C75BC;
    }
  }
}

.ul_close {
  margin-left: -20px;
  margin-top: -15px;
  margin-bottom: 1rem;
}

.info_version {
  font-family:"Times New Roman",serif;
  color:windowtext;
  text-decoration:none;
  vertical-align:baseline;
  font-size: 11px;
}